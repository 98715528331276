@import "./Colors.scss";

.NavBar {
  background-color: $nav_bar_color_dark;
  color: $base_font_dark;
  display: flex;
  justify-content: space-between;
  padding: 5px clamp(5%, 2vw, 20%);
  max-height: 30px;

  max-width: 1700px;
  left: 0; // Stretch from the left edge
  right: 0; // to the right edge
  margin: 0 auto;

  /*@media only screen and (min-width: 1024px) {
    padding: 5px clamp(10%, 2vw, 50%);
  }

  @media only screen and (min-width: 768px) {
    padding: 5px clamp(10%, 2vw, 50%);
  }*/
}

.NavBar a {
  position: relative;
  padding-bottom: 5px;
  font-size: 15px;
  color: $base_font_dark;

  @media only screen and (min-width: 1024px) {
    font-size: 15px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 15px;
  }
}

.NavBar a:hover {
  color: $gold_color;
}

.NavBar a::before {
  content: "";
  position: absolute;
  background-color: $nav_bar_color_dark;
  left: 0;
  top: 0px;
  height: 100%;
  width: 100%;

  animation: 1.5s ease-in-out 0s forwards running MaskDown;
}

.NavBar a::after {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: $gold_color;
  transform: scaleX(0);
  transition: transform 0.15s ease-in-out;
}

.NavBar a:hover:after {
  transform: scaleX(1);
}

@keyframes MaskDown {
  from {
    height: 100%;
  }

  to {
    height: 0%;
  }
}