/*$base_color_dark: #D4CDC5;
$base_color_light: #7F7B77;
$base_font_dark: #4b4b4b;
$base_font_light: #D4CDC5;*/
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css?family=Trirong");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@1,200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow-x: hidden;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
  color: #d5cdc4;
  font-family: Inter, sans-serif;
  font-size: clamp(10px, 5vw, 20px);
}