// $base_color_dark: #0d1c20;
// $base_color_light: #d5cdc4;

// Light theme
/*$base_color_dark: #D4CDC5;
$base_color_light: #7F7B77;
$base_font_dark: #4b4b4b;
$base_font_light: #D4CDC5;*/

// Inversed Light theme
//$base_color_dark: #7F7B77;

// $base_color_dark: #585654;
$galleryCard_back_color: #585654;
$base_color_dark: black;
$nav_bar_color_dark: black;

$base_color_light: #D4CDC5;
$base_font_dark: #D4CDC5;
$base_font_light: #4b4b4b;
$gold_color: #e5c93c //$gold_color: #cfb532