@import "./Colors.scss";

$urlPrefix: "./assets/img/gallery/";

// ==================================================
// Styles
// ==================================================

// --------------------------------------------------
.show.gallery_section {
  .gallery_container {
    animation: 0.8s ease-in-out 0s forwards running ToTop;
  }
}

// --------------------------------------------------
.galleryTitle {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 0 5% 0 5%;
  overflow: hidden;
}

// --------------------------------------------------
.show.gallery_container {
  opacity: 1;
  transition: all 1.5s;
}

// --------------------------------------------------
.hidden.gallery_container {
  opacity: 0;
}

// --------------------------------------------------
.galleryTitle h2 {
  text-align: center;
  font-family: "Italiana", sans-serif;
  background-size: cover;
  color: $base_font_dark;
}

// --------------------------------------------------
.gallery_container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}

// --------------------------------------------------
.galleryCollection {
  padding-bottom: 30px;
  position: relative;

  h3 {
    font-family: "Italiana", sans-serif;
    font-size: 50px;
    color: $base_color_dark;
    margin-top: 20px;
  }
}

// --------------------------------------------------
.galleryCollection:not(:last-child) {
  &::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 10%;
    width: 80%;
    border-bottom-style: solid;
    border-bottom-color: $base_color_dark;
    border-bottom-width: 1px;
  }
}

// --------------------------------------------------
.gallery_cards_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

// --------------------------------------------------
.galleryCard {
  background-color: $galleryCard_back_color;
  border-radius: 10px;
  margin: 20px 5px;
  width: 350px;
  height: 400px;

  color: $base_font_dark;
  box-shadow: rgba(0, 0, 0, 0.25) 2.95px 2.95px 2.6px;
  transform: scale(1);
  transition-property: transform;
  transition-duration: 0.4s;
  animation: 0.6s ease-out 0s forwards running ShadowAnimReversed;

  &:hover {
    color: $gold_color;
    transform: scale(1.015);
    animation: 0.6s ease-out 0s forwards running ShadowAnim;

    .galleryCard_img {
      -ms-transform: scale(1.15);
      /* IE 9 */
      -webkit-transform: scale(1.15);
      /* Safari 3-8 */
      transform: scale(1.15);
    }
  }

  .galleryClass_header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 10px;
    max-height: 50px;
  }

  .galleryCard_img_container {
    width: 100%;
    overflow: hidden;
  }

  .galleryCard_img {
    width: 100%;
    max-height: 300px;
    min-height: 300px;
    object-fit: cover;
    object-position: center;
    transition: transform 4s;
  }

  .galleryCard_footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0;
    text-align: left;
    justify-content: center;
    align-items: center;
    min-height: 25px;
    font-size: 15px;

    p {
      margin-top: 10px;
    }
  }
}

// --------------------------------------------------
// The Modal (background)
// --------------------------------------------------
.gallery_modal {
  position: fixed;
  z-index: 10000;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// --------------------------------------------------
// Modal Content (Image) 
// --------------------------------------------------
.gallery_modal_content {
  width: 100%;
  max-height: 90%;
  min-height: 300px;
  object-fit: contain;
  object-position: center;
  pointer-events: none;
}

// --------------------------------------------------
// Add Animation - Zoom in the Modal
// --------------------------------------------------
.gallery_modal_content,
#caption {
  opacity: 1;
  animation: 0.6s ease-out 0s forwards zoom;
}

// --------------------------------------------------
// The Close Button
// --------------------------------------------------
.close_modal {
  position: absolute;
  top: 15px;
  right: 35px;
  color: $base_color_light;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close_modal:hover,
.close_modal:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

// ==================================================
// keyframes
// ==================================================

// --------------------------------------------------
@keyframes ToTop {
  from {
    margin-top: 180px;
  }

  to {
    margin-top: 0px;
  }
}

// --------------------------------------------------
@keyframes zoom {
  from {
    opacity: 0;
    transform: scale(0)
  }

  to {
    opacity: 1;
    transform: scale(1)
  }
}

// --------------------------------------------------
@keyframes ShadowAnim {
  from {
    box-shadow: rgba(0, 0, 0, 0.0) 0px 0px 0px;
  }

  to {
    box-shadow: rgba(0, 0, 0, 0.30) 13px 13px 13px;
  }
}

// --------------------------------------------------
@keyframes ShadowAnimReversed {
  from {

    box-shadow: rgba(0, 0, 0, 0.30) 13px 13px 13px;
  }

  to {
    box-shadow: rgba(0, 0, 0, 0.0) 0px 0px 0px;
  }
}

// ==================================================
// Media queries
// ==================================================

// --------------------------------------------------
// Tablet
// --------------------------------------------------
@media (min-width: 768px) {
  /*.galleryCard {
    margin: 20px 10px;
    width: 400px;
    height: 400px;
  }*/
}

// --------------------------------------------------
// Desktop
// --------------------------------------------------
@media (min-width: 992px) {
  /*.galleryCard {
    margin: 20px 20px;
    width: 400px;
    height: 400px;
  }*/
}

// --------------------------------------------------
// High Rez Desktop
// --------------------------------------------------
@media (min-width: 1600px) {
  .galleryCard {
    margin: 20px 50px;
    width: 400px;
    height: 400px;
  }
}

// ==================================================