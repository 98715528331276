@import "./Colors.scss";

// ==================================================
// Mixins
// ==================================================

// --------------------------------------------------
// calc_grid_row_size
// --------------------------------------------------
@mixin calc_grid_row_size($width, $height) {
  @if $width>$height {
    grid-template-rows: minmax(15%, auto) minmax(40%, auto) minmax(15%, auto) minmax(5%, auto);
  }

  @else {
    grid-template-rows: minmax(15%, auto) minmax(40%, auto) minmax(15%, auto) minmax(5%, auto);
  }
}

// ==================================================
// Styles
// ==================================================

// --------------------------------------------------
// show.App_header
// --------------------------------------------------
.show.App_header {
  animation: 0.5s linear 0.3s forwards running ToTop;

  /*.black_Han_Big::before {
    animation: MaskUp 1.5s forwards;
  }

  .Italiana::before {
    animation: MaskDown 1.1s forwards;
  }*/

  /*.main_pict {
    animation: 1.0s ease-in forwards Radius, 1.0s ease-in forwards MarginX;
  }*/

  .description {
    animation: fadeIn 1.5s forwards;
  }

  .description img {
    //animation: 0.5s linear 0.3s forwards running ToRound, 0.5s ease-in-out 0.3s forwards running fadeIn;
  }

  .description h2,
  .description_Text {
    animation: 0.5s linear 0.3s forwards running LineDeployY;
  }
}

// --------------------------------------------------
// App_header
// --------------------------------------------------
.App_header {
  background-color: $base_color_dark;
  color: $base_font_dark;
  //min-height: calc(100vh - 35px);
  //max-height: calc(100vh - 35px);
  overflow: hidden;
  /*display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(20%, auto) minmax(40%, auto) minmax(80px, auto) minmax(50px, auto);
  grid-column-gap: 0px;
  grid-row-gap: 0px;*/
  text-align: left;
  margin: 0px;
  padding-top: 20px;

  h2 {
    //color: white;
    font-size: clamp(12px, 12vw, 50px);
    //font-size: clamp(50px, 9vw, 100px);
    font-weight: 200;
    line-height: 0.8;
    text-align: center;
    margin: 0;
  }

  max-width: 1700px;
  left: 0; // Stretch from the left edge
  right: 0; // to the right edge
  margin: 0 auto;

  .pictTextContainer {
    position: relative;
    width: 100%;
    overflow: hidden;
    max-width: inherit;
    //margin: 0 auto;
    max-width: 1200px;
    max-height: 600px;
    margin-top: 40px;
  }

  .main_pict {
    width: auto;
    min-width: 100%;
    max-width: none;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //width: 800px;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0 0 40px;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;

    p {
      padding: 0;
      text-align: left;
    }
  }

  .overlay:after {
    content: '';
    width: 5px;
    height: 100%;
    background-color: yellow;
    position: absolute;
    left: 1px;
    top: 0;
    transform: rotate(-3deg);
    transform-origin: 0 0;
    backface-visibility: hidden;
  }
}

// --------------------------------------------------
// black_Han_Big
// --------------------------------------------------
.black_Han_Big {
  padding-left: 5%;
  padding-right: 5%;
  font-family: "Jost", sans-serif;
  font-weight: bold;
  //font-size: clamp(70px, 10vw, 90px);
  font-size: 70px;
  //text-transform: uppercase;
  //position: relative;
  overflow: hidden;
  //line-height: 0.95;
  text-align: center;

  /*&:before {
    content: "";
    position: absolute;
    background-color: $base_color_dark;
    left: 4%;
    top: 0px;
    height: 100%;
    min-width: 95%;
  }*/
}

// --------------------------------------------------
// description
// --------------------------------------------------
.description {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  min-height: 280px;
  opacity: 0;
  justify-content: end;
  margin: 0 20px 0 20px;
  //background-image: url("../assets/img/PORTRAIT_ATELIER 2.jpg");

  img {
    padding: 0 0;
    //max-width: 300px;
    //width: 30%;
    height: auto;
    //opacity: 0;
    //transform: scale(0.8);
    //filter: grayscale(30%);
    //-webkit-filter: grayscale(30%);
  }

  h2 {
    text-transform: uppercase;
    font-family: Inter, sans-serif;
    max-width: 400px;
    font-size: clamp(10px, 3vw, 17px);
    padding: 0 10px;
    line-height: 1;
    opacity: 0;
  }


}

.description_Text {
  font-size: 18px; //clamp(18px, 3vw, 21px);
  color: #fff;
}

// --------------------------------------------------
// Italiana
// --------------------------------------------------
.Italiana {
  position: relative;
  margin-top: 20px;
  margin-bottom: 0px;
  padding: 0 5% 0 0;
  font-family: "Italiana", sans-serif;
  font-size: clamp(70px, 12vw, 200px);
  font-weight: 200;
  line-height: 0.8;
  text-align: end;

  &:before {
    content: "";
    position: absolute;
    background-color: $base_color_dark;
    left: 4%;
    top: 0px;
    height: 100%;
    min-width: 92%;
  }

  span {
    font-size: clamp(30px, 12vw, 50px);
  }
}

// --------------------------------------------------
// thirdSection
// --------------------------------------------------
#thirdSection {
  height: 100vh;
}

// ==================================================
// keyframes
// ==================================================
@keyframes Radius {
  from {
    border-radius: 0 0 0 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  }

  to {
    border-radius: 50px 0px 50px 0px;
    -moz-border-radius: 50px 0px 50px 0px;
    -webkit-border-radius: 50px 0px 50px 0px;
  }
}

// --------------------------------------------------
// ToTop
// --------------------------------------------------
@keyframes ToTop {
  from {
    padding-top: 20px;
  }

  to {
    padding-top: 0px;
  }
}

// --------------------------------------------------
// ToRound
// --------------------------------------------------
@keyframes ToRound {
  from {
    border-radius: 0%;
  }

  to {
    border-radius: 50%;
  }
}

// --------------------------------------------------
// MaskDown
// --------------------------------------------------
@keyframes MaskDown {
  from {
    height: 100%;
  }

  to {
    height: 0%;
  }
}

// --------------------------------------------------
// MaskUp
// --------------------------------------------------
@keyframes MaskUp {
  from {
    top: 0;
    height: 100%;
  }

  to {
    top: 100%;
    height: 0%;
  }
}

// --------------------------------------------------
// LineDeployY
// --------------------------------------------------
@keyframes LineDeployY {
  from {
    line-height: 0;
    opacity: 0;
  }

  to {
    line-height: 1;
    opacity: 1;
  }
}

// --------------------------------------------------
// fadeIn
// --------------------------------------------------
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// --------------------------------------------------
// fadeOut
// --------------------------------------------------
@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// ==================================================
// Media queries
// ==================================================

// --------------------------------------------------
// Tablet
// --------------------------------------------------
@media screen and (min-width: 768px) {

  .App_header {
    grid-template-rows: minmax(15%, auto) minmax(40%, auto) minmax(15%, auto) minmax(5%, auto);
  }

  .black_Han_Big {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 5%;
    padding-right: 5%;
    //line-height: 1.2;
  }

  .Italiana {
    padding: 0 5% 0 25%;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .App_header {
    .overlay {
      left: 50%;
    }
  }
}



// --------------------------------------------------
// Desktop
// --------------------------------------------------
@media screen and (min-width: 992px) {

  .App_header {
    grid-template-rows: minmax(20%, auto) minmax(40%, auto) minmax(15%, auto) minmax(5%, auto);
  }

  .black_Han_Big {
    //font-size: clamp(70px, 10vw, 160px);
  }

  .Italiana {
    padding: 0 5% 0 0;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .App_header {
    .overlay {
      left: 60%;

      p {
        padding: 0 0 0 20px;
      }
    }
  }
}

// --------------------------------------------------
// Laptop
// --------------------------------------------------
@media screen and (min-width: 1024px) {

  .App_header {
    .overlay {
      left: 60%;

      p {
        padding: 0;
      }
    }
  }
}

// --------------------------------------------------
// Laptop
// --------------------------------------------------
@media screen and (min-width: 1440px) {

  .App_header {
    .overlay {
      left: 60%;

      p {
        padding: 0 0 0 20px;
      }
    }
  }
}

// --------------------------------------------------
// High Rez Desktop
// --------------------------------------------------
@media screen and (min-width: 1600px) {
  .Italiana {
    padding: 0 5% 0 0;
    margin-top: 20px;
    margin-bottom: 0px;
  }
}

// --------------------------------------------------
// 
// --------------------------------------------------
@media screen and (min-height: 1300px) {
  .description {

    margin-bottom: 50px;
    //    min-height: 580px;
    min-height: 50%;
    opacity: 0;
  }

  .description_Text {
    font-size: clamp(17px, 3vw, 25px);
    padding: 0 10px;
    line-height: 1;
    opacity: 0;
  }
}

// --------------------------------------------------