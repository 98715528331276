@import "./Colors.scss";

// ==================================================
// Styles
// ==================================================
.activitySection {
  padding: 0 10%;
  font-family: Inter, sans-serif;

  h3 {
    padding-top: 40px;
    margin-bottom: 40px;
    font-family: "Italiana", sans-serif;
    font-size: 50px;
    color: $base_color_dark;
  }

  p:last-child {
    padding-bottom: 40px;
  }

  .activity_Title {
    text-align: start;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
  }

  .activity_Text {
    text-align: start;
    padding: 5px 20px;
  }
}

.contact_video {
  //display: flex;
}

#video_contact {
  margin: 30px 0px;
  //max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

// ==================================================
// Media queries
// ==================================================
// Tablet
@media screen and (min-width: 768px) {
  .activitySection {
    padding: 0 22%;
  }

  .activity_Text {
    padding: 5px 50px;
  }

  #video_contact {
    width: 80%;
    height: auto;
  }
}

// Desktop
@media screen and (min-width: 992px) {
  #video_contact {
    width: 80%;
    height: auto;
  }
}

// ==================================================