@import "./Colors.scss";


._section {
  min-height: 100vh;
  color: $base_color_dark;
  background-color: $base_color_light;
}

.gallery_section {
  height: auto;
  background-color: $base_color_light;

}

.activitySection {
  padding-bottom: 30px;
  font-family: Inter, sans-serif;
}

.contactSection {
  color: $base_color_light;
  background-color: $base_color_dark;

  h2,
  h3 {
    font-family: "Italiana", sans-serif;
    font-size: 50px;
    margin-top: 20px;
    padding-top: 20px;
    padding: 0 20%;
  }

  h2 {
    //margin-top: 30px;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 50px;
  }
}

.show {
  opacity: 1;
  transition: all 1.2s;
}

.hidden {
  opacity: 0;
}