@import "./_Colors.scss";

.social_bar_header,
.social_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 50px;
  margin-top: 50px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

.social_bar_header {
  align-items: center;
  justify-content: center;
  padding-left: 0;
  margin: 30px 0 20px 0;
}

.social_btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid $base_color_light;
  background-color: $base_color_dark;
  color: $base_color_light;
  cursor: pointer;
  font-size: 25px;
  opacity: 0;
  animation: 1s linear 1s forwards running fadeIn;

  &:hover {
    transform: scale(1.1);
    transition: transform .2s linear;

    border: 2px solid $gold_color;
    background-color: $base_color_dark;
    color: $gold_color;
  }
}

// =========================================
// keyframes
// =========================================
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes colorFade {
  from {
    background-color: $base_color_light;
  }

  to {
    background-color: $base_color_dark;
  }
}

// ==================================================
// Media queries
// ==================================================
// Tablet
@media (min-width: 768px) {
  .social_bar_header {
    //align-items: left;
    //justify-content: left;
    //padding-left: 5%;
  }

  .social_btn {
    width: 50px;
    height: 50px;
    font-size: 25px;
  }
}

// Desktop
@media (min-width: 992px) {}