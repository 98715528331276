@import "./Colors.scss";

.about_container {
  min-height: 100vh;
  background-color: $base_color_dark;
  color: $base_font_dark;
  display: flex;
  flex-direction: column;
  margin: 0 5%;
  margin-bottom: 40px;
  overflow: hidden;

  font-family: Inter, sans-serif;

  h2,
  h3 {
    font-family: "Italiana", sans-serif;
  }

  h2 {
    //margin-top: 30px;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 50px;
  }

  .demarche_title {
    margin-bottom: 20px;
  }
}

.about_parallax {
  width: 100%;
  max-height: 300px;
  min-height: 300px;
  margin-top: 40px;

  background-image: url("../../public/assets/img/BenAbout_small.webp");

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  -webkit-filter: grayscale(20%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(20%);
  margin-bottom: 20px;
}

.show.about_container {
  .about_parallax {
    animation: 1.0s ease-in forwards Radius, 1.0s ease-in forwards MarginX;
  }
}

.about_title {
  text-align: start;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
}

.sensibilisation_Text {
  text-align: start;
  padding: 10px 20px;
  border-left: 4px solid $gold_color;
}

.contact_video {
  display: flex;
  justify-content: center;
  align-items: center;
}

#video_about {
  margin: 30px 0px;
  //max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

// ==================================================
// keyframes
// ==================================================
@keyframes Radius {
  from {
    border-radius: 0 0 0 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  }

  to {
    border-radius: 50px 0px 50px 0px;
    -moz-border-radius: 50px 0px 50px 0px;
    -webkit-border-radius: 50px 0px 50px 0px;
  }
}

@keyframes MarginX {
  /*from {
    margin: 0px 0px;
    margin-top: 40px;
  }

  to {
    margin: 0px 5%;
    margin-top: 40px;
  }*/
}

// ==================================================
// Media queries
// ==================================================

// --------------------------------------------------
// Tablet
// --------------------------------------------------
@media screen and (min-width: 768px) {
  .about_container {
    margin: 0 10%;
  }

  .about_parallax {
    max-height: 400px;
    min-height: 400px;
    background-image: url("../../public/assets/img/BenAbout.webp");
  }

  .sensibilisation_Text {
    border-left: 4px solid $gold_color;
    margin: 10px 80px 50px 50px;
  }

  #video_about {
    width: 55%;
  }
}

// --------------------------------------------------
// Desktop
// --------------------------------------------------
@media screen and (min-width: 992px) {}

// --------------------------------------------------
// IOS Fixed position HACK
// --------------------------------------------------
@supports (-webkit-overflow-scrolling: touch) {
  .about_parallax {
    background-attachment: scroll;
  }
}