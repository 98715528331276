@import "./_Colors.scss";

.to_top_btn_visible {
  width: 40px;
  height: 40px;
  right: 20px;
  position: fixed;
  z-index: 9999;
  border: 1px solid $base_color_dark;
  border-radius: 50%;
  background-color: $base_color_light;
  color: $base_color_dark;
  cursor: pointer;
  font-size: 18px;
  opacity: 0;
  animation: 1s linear 0s forwards running fadeIn;
}

.to_top_btn_visible:hover {
  //color: $gold_color;
  //border: 1px solid $gold_color;
  background-color: $gold_color;
}

.to_top_btn_unVisible {
  width: 40px;
  height: 40px;
  right: 20px;
  position: fixed;
  z-index: 9999;
  border: 1px solid $base_color_dark;
  border-radius: 50%;
  //background-color: red;
  color: $base_color_dark;
  cursor: pointer;
  font-size: 18px;
  opacity: 1;
  animation: 1.3s linear 0s forwards running fadeOut;
}

.to_top_btn_default {
  bottom: 20px;
}

.to_top_btn_middle {
  bottom: 10%;
}

.to_top_btn_top {
  bottom: 70%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// ==================================================
// Media queries
// ==================================================
// Tablet
@media (min-width: 768px) {
  .to_top_btn_visible {
    width: 50px;
    height: 50px;
  }

  .to_top_btn_unVisible {
    width: 50px;
    height: 50px;
  }

  .to_top_btn_middle {
    bottom: 50%;
  }
}

// Desktop
@media (min-width: 992px) {
  .to_top_btn_middle {
    bottom: 50%;
  }
}

// ==================================================