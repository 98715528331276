@import "./Colors.scss";

// ==================================================
// Mixins
// ==================================================
@mixin align_left {
  justify-self: start;
  align-self: start;
}

@mixin align_right {
  justify-self: end;
  align-self: start;
}

// ==================================================
// Styles
// ==================================================

// --------------------------------------------------
.contactSection {
  margin: 0 5%;
  font-family: Inter, sans-serif;
  color: $base_font_dark;

  h3 {
    padding-top: 40px;
    margin-bottom: 40px;
    font-family: "Italiana", sans-serif;
    font-size: 50px;
  }
}

// --------------------------------------------------
.contact_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// --------------------------------------------------
.contact_content {
  p {
    margin: 10px 0;
  }

  a {
    color: $base_font_dark;
  }

  a:hover {
    color: $gold_color;
  }
}

// --------------------------------------------------
.contact_title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 300;
}

// --------------------------------------------------
.contact_pict_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  gap: 50px;
}

// --------------------------------------------------
.contact_pict {
  border-radius: 50%;
  width: 50;
  height: auto;
  overflow: hidden;
}

// ==================================================
// Media queries
// ==================================================

// --------------------------------------------------
// Tablet
// --------------------------------------------------
@media (min-width: 768px) {
  .contactSection {
    margin: 0 10%;
  }

  .contact_grid {
    padding: 0 20%;
  }

  .contact_pict_container {
    margin-top: 200px;
  }
}

// --------------------------------------------------
// Desktop
// --------------------------------------------------
@media (min-width: 992px) {
  .contactSection {
    margin: 0 20%;
  }

  .contact_grid {
    padding: 0 30%;
  }

  .contact_pict_container {
    margin-top: 100px;
  }
}

// ==================================================